@import '~antd/dist/antd.less';

body {
    font-family: 'Nunito', sans-serif;
    background-color: #f0f2f5;
}

.ant-layout {
    background-color: transparent;
}

.container,
.container-fluid {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0 15px;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

.ant-dropdown {
    min-width: 200px !important;
}

.ant-select-lg,
.ant-input-lg,
.ant-picker-large .ant-picker-input > input {
    font-size: 14px;
}

.ant-picker-large {
    padding: 8px 11px 8px;
}

.ant-input-number {
    width: 100%;
}

.ant-input-prefix {
    margin-right: 11px;
}

.ant-input-affix-wrapper {
    color: rgba(0, 0, 0, 0.25)
}

.ant-menu-vertical .ant-menu-item {
    margin-bottom: 4px !important
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
}

.ant-menu-submenu > .ant-menu {
    background-color: rgba(0, 0, 0, 0.04);
}

.ant-menu > .ant-menu-item-divider {
    margin: 0;
}

.ant-picker {
    width: 100%;
}

.ant-radio-wrapper {
    display: block;
    line-height: 30px;
}

.ant-tabs-nav-wrap {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.ant-space-vertical {
    width: 100%;
}

.ant-card-body {
    height: 100%;
}

.g2-tooltip {
    border: 1px solid #f0f0f0;
    box-shadow: none !important;
}

.ant-drawer-body {
    padding: 0;
}

.ant-popover-inner {
    overflow: hidden;
}

.ant-popover-inner-content {
    min-width: 200px;
    padding: 0;
}

.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
    padding: 0;
}

.ant-popover-arrow {
    display: none;
}

.card-link:active,
.card-link:hover {
    background-color: #f0f0f0;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: 400;
}

.ant-picker-calendar-mode-switch {
    display: none;
}

.ant-picker-calendar-date-content {
    display: flex;
    align-items: center;
}

.ant-badge-status-text {
    margin-left: 0;
}
@primary-color: #ea345c;@link-color: #32b8ce;@border-radius-base: 4px;@typography-title-margin-top: 0px;@typography-title-margin-bottom: 0px;